import * as React from "react";
import * as Styles from "./Social.module.css";
import { Grid } from "@stories/Containers/Grid/Grid";
import { StaticImage } from "gatsby-plugin-image";

export const Social = () => {
  const { section, ribbon } = Styles;

  return (
    <Grid customGrid="social" size="200px" gap={10}>
      <a
        href="https://www.facebook.com/profile.php?id=61555684195921"
        target="_blank"
      >
        <StaticImage
          src={`../../../../../images/facebook.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </a>
      <a href="https://www.instagram.com/barraliebre/" target="_blank">
        <StaticImage
          src={`../../../../../images/instagram.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </a>
      <a href="https://wa.me/+529991026029" target="_blank">
        <StaticImage
          src={`../../../../../images/whatsapp.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/95750330/admin/feed/posts/"
        target="_blank"
      >
        <StaticImage
          src={`../../../../../images/linkedin.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </a>
    </Grid>
  );
};
