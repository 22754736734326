import * as React from "react";
import * as Styles from "./BurgerNav.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Nav } from "@stories/Texts/Nav/Nav";
import { Link } from "gatsby";
import { FaBars } from "@stories/Buttons/FaBars/FaBars";

export const BurgerNav = () => {
  const { navbar, modal, modal_ribbon, modal_hide, logo } = Styles;
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <nav className={navbar}>
      <Link to="/" className={logo}>
        <StaticImage
          src={`../../../../images/mobile-icon.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </Link>
      <FaBars state={isOpen} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <div className={modal_ribbon} onClick={() => setIsOpen(!isOpen)} />
      )}
      <div className={isOpen ? modal : modal_hide}>
        <Nav url="/" name="Inicio" onClick={() => setIsOpen(!isOpen)} />
        <Nav
          url="/nosotros"
          name="Nosotros"
          onClick={() => setIsOpen(!isOpen)}
        />
        <Nav
          url="/contacto"
          name="Contacto"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
    </nav>
  );
};
