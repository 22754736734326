import * as React from "react";
import * as Styles from "./Footer.module.css";
import { Grid } from "@stories/Containers/Grid/Grid";
import { Subtitle } from "@stories/Texts/Subtitle/Subtitle";
import { StaticImage } from "gatsby-plugin-image";
import { Text } from "@stories/Texts/Text/Text";
import { Section } from "@stories/Containers/Section/Section";
import { Social } from "./Social/Social";
import { maps } from "@data/map";
import { Link } from "gatsby";

export const Footer = () => {
  const { section, container, button, mail, ribbon } = Styles;

  return (
    <footer className={section}>
      <Section>
        <Grid customGrid="footer">
          <div>
            <Subtitle
              title="Enlaces utiles"
              color="var(--white)"
              alignment="left"
              margin="0px 0px 20px 0px"
            />
            {maps.map((map) => {
              return (
                <div className={container}>
                  <Link to={map.url} target="_blank" className={button}>
                    {map.label}
                  </Link>
                </div>
              );
            })}
          </div>
          <div>
            <Subtitle
              title="Barra Liebre"
              color="var(--white)"
              margin="0px 0px 20px 0px"
            />
            <Text
              label="Somos la liebre que va de lugar en lugar abasteciendo los mejores restaurantes, 
            hoteles, antros, centros de convenciones y bares de todo México. Te proveemos 
            de las mejores marcas de México de agua, refresco, cerveza, vinos y licores para que 
            puedas sacar adelante tu proyecto."
              color="var(--white)"
            />
          </div>
          <div>
            <Subtitle
              title="¡Únete a la madriguera!"
              color="var(--white)"
              margin="0px 0px 20px 0px"
            />
            <Grid customGrid="icons" gap={20} marginBottom={20}>
              <div className={mail}>
                <StaticImage
                  src={`../../../../images/mail.png`}
                  alt=""
                  placeholder="blurred"
                  quality={100}
                  layout="constrained"
                />
              </div>
              <div>
                <Text label="Adrián Zubieta" color="var(--white)" />
                <Text label="Director General" color="var(--white)" />
                <Text label="adrian@barraliebre.com" color="var(--white)" />
              </div>
            </Grid>
            <Grid customGrid="icons" gap={20} marginBottom={20}>
              <StaticImage
                src={`../../../../images/phone.png`}
                alt=""
                placeholder="blurred"
                quality={100}
                layout="constrained"
              />
              <div>
                <Text
                  label="999 102 6029"
                  color="var(--white)"
                  alignment="left"
                />
              </div>
            </Grid>
            <Social />
          </div>
        </Grid>
      </Section>
      <Text
        label="Evita el exceso"
        color="var(--white)"
        alignment="center"
        margin="20px"
      />
      <div className={ribbon}>
        <Text
          label="Todos los derechos reservados, Barra Liebre"
          color="var(--white)"
          alignment="left"
        />
      </div>
    </footer>
  );
};
