import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import Layout from "./src/components/Layout/Layout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}: any) => {
  return <Layout {...props}>{element}</Layout>;
};
