import React from "react";
import * as Styles from "./FaBars.module.css";

interface FaBarsProps {
  state: boolean;
  onClick?: () => void;
}

export const FaBars = ({ state, onClick, ...props }: FaBarsProps) => {
  const { burgermenu, fa_bars, fa_bars_close } = Styles;

  return (
    <button className={burgermenu} onClick={onClick} {...props}>
      <span className={state ? fa_bars_close : fa_bars} />
    </button>
  );
};
