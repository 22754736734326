import * as React from "react";
import { ReactNode } from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import { Navbar } from "@components/Layout/Navigation/Navbar/Navbar";
import { BurgerNav } from "@components/Layout/Navigation/BurgerNav/BurgerNav";
import { Footer } from "@components/Layout/Navigation/Footer/Footer";
import { Page } from "@stories/Containers/Page/Page";
import { ScrollButton } from "@stories/Buttons/ScrollButton/ScrollButton";
import "@styles/globals.css";
import "@styles/embla.css";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Page>
      <Header />
      <Navbar />
      <BurgerNav />
      <main>{children}</main>
      <ScrollButton />
      <Footer />
    </Page>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
