import React, { ReactNode } from "react";
import * as Styles from "./Page.module.css";

interface PageProps {
  children: ReactNode;
}

export const Page = ({ children, ...props }: PageProps) => {
  return (
    <main className={Styles.page} {...props}>
      {children}
    </main>
  );
};
