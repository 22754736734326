import * as React from "react";
import * as Styles from "./Navbar.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Nav } from "@stories/Texts/Nav/Nav";
import { Link } from "gatsby";
import { navigation } from "@data/navigation";

export const Navbar = () => {
  const { navbar, content, logo } = Styles;

  return (
    <nav className={navbar}>
      <Link to="/" className={logo}>
        <StaticImage
          src={`../../../../images/logo.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </Link>
      <div className={content}>
        {navigation.map((nav) => {
          return <Nav url={nav.url} name={nav.name} />;
        })}
      </div>
    </nav>
  );
};
